import { useSelector } from "react-redux";
import formatTemperature from "../../Functions/formatTemperature";
import DeviceTypeBase from "./DeviceTypeBase";

export default function Tracker(props) {
  const { isShare } = props;

  let columns = [];

  const deviceHistory = useSelector((state) => state.deviceHistory);

  let last_device_history = [];

  if (deviceHistory && deviceHistory.length > 0) {
    last_device_history = JSON.parse(
      deviceHistory[deviceHistory.length - 1].data
    );
  }

  let hasTemp = false;
  let hasHum = false;

  if (last_device_history) {
    hasTemp = Object.keys(last_device_history).includes("temperature");
    hasHum = Object.keys(last_device_history).includes("humidity");

    //check its not none, if it is, hide it

    if (!last_device_history.temperature) {
      hasTemp = false;
    }
    if (!last_device_history.humidity) {
      hasHum = false;
    }
  }

  if (hasTemp) {
    columns.push({
      field: "temperature",
      title: "Temperature",
      icon: "thermometer",
      iconSize: "4x",
      formatter: (value) => formatTemperature(value, false),
      graphValueFormatter: (value) => formatTemperature(value, false),
      aspect: 10,
      metricWidth: 4,
      graphWidth: 12,
      unit: formatTemperature(0, false, true),
      textsize: "h3",
    });
  }

  if (hasHum) {
    columns.push({
      field: "humidity",
      title: "Humidity",
      icon: "Humidity",
      iconSize: "4x",
      formatter: (value) => value + "%",
      graphValueFormatter: (value) => value + "%",
      aspect: 10,
      metricWidth: 6,
      graphWidth: 12,
      unit: "%",
      textsize: "h3",
    });
  }

  return (
    <DeviceTypeBase
      isShare={isShare}
      columns={columns}
      showBattery={true}
      isTracker={true}
      hideStats={true}
      sideBySide={true}
    />
  );
}
