import formatTemperature from "../../Functions/formatTemperature";
import { Thermostat } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { Card } from "@mui/material";
import { CardContent } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import GaugeComponent from "react-gauge-component";
import AvgAndMax from "../Cards/avgAndMax";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

export default function TemperatureGuague(props) {
  const theme = useTheme();
  const { title, field = "temperature" } = props;

  let value = 0;

  const deviceHistory = useSelector((state) => state.deviceHistory);

  const tmp = useMemo(
    () => localStorage.getItem("temperature_unit") || "C",
    []
  );

  if (deviceHistory.length) {
    const jsonData = JSON.parse(deviceHistory[deviceHistory.length - 1].data);
    let value_field = jsonData[field];

    //if value is an array, return first value
    if (Array.isArray(value)) {
      value_field = value[0];
    }

    value = value_field || 0;
  }

  const defaultArch = useMemo(() => {
    const defaultArchC = [
      { limit: 0, color: "#0061f2" },
      { limit: 10, color: "#00c1ff" },
      { limit: 20, color: "#5BE12C" },
      { limit: 30, color: "#F5CD19" },
      { limit: 50, color: "#EA4228" },
    ];

    const defaultArchF = [
      { limit: 0, color: "#0061f2" },
      { limit: 30, color: "#00c1ff" },
      { limit: 50, color: "#5BE12C" },
      { limit: 80, color: "#F5CD19" },
      { limit: 100, color: "#EA4228" },
    ];

    return tmp === "F" ? defaultArchF : defaultArchC;
  }, [tmp]);

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Thermostat
            sx={{
              color: theme.palette.text.secondary,
              opacity: 0.8,
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
              flexGrow: 1,
            }}
          >
            {title ? title : "Temperature"}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
            }}
          >
            {formatTemperature(value)}
          </Typography>
        </Box>
        <GaugeComponent
          type="radial"
          arc={{
            width: 0.2,
            padding: 0.005,
            cornerRadius: 1,
            subArcs: defaultArch,
          }}
          pointer={{
            color: "#345243",
            length: 0.8,
            width: 15,
            elastic: true,
          }}
          value={value}
          minValue={tmp === "F" ? 0 : -20}
          maxValue={tmp === "F" ? 100 : 50}
          labels={{
            valueLabel: { formatTextValue: (value) => "" },
          }}
        />
        <Box sx={{ mt: "auto" }}>
          <AvgAndMax
            type={field}
            value={field}
            unit={formatTemperature(0, false, true)}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
