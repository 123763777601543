import { useTheme } from "@mui/material";
import { Card } from "@mui/material";
import { CardContent } from "@mui/material";
import { Box } from "@mui/system";
import { Waves } from "@mui/icons-material";
import { Typography } from "@mui/material";
import GaugeComponent from "react-gauge-component";
import AvgAndMax from "../Cards/avgAndMax";
import { useSelector } from "react-redux";

export default function HumidityGuague(props) {
  const theme = useTheme();

  const defaultArch = [
    {
      limit: 20,
      //red
      color: "#EA4228",
    },
    {
      limit: 30,
      // yellow
      color: "#F5CD19",
    },
    {
      limit: 70,
      //green
      color: "#5BE12C",
    },
    {
      limit: 80,
      color: "#F5CD19",
    },
    {
      limit: 100,
      color: "#EA4228",
    },
  ];

  const { field = "humidity", title = "Humidity" } = props;

  let value = 0;

  const deviceHistory = useSelector((state) => state.deviceHistory);

  if (deviceHistory.length) {
    const jsonData = JSON.parse(deviceHistory[deviceHistory.length - 1].data);
    value = jsonData[field];

    //if value is an array, return first value
    if (Array.isArray(value)) {
      value = value[0];
    }
  }

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
        minHeight: 300,
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Waves
            sx={{
              color: theme.palette.text.secondary,
              opacity: 0.8,
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
              flexGrow: 1,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
            }}
          >
            {value !== null ? value + "%" : "N/A"}
          </Typography>
        </Box>
        <GaugeComponent
          type="radial"
          arc={{
            width: 0.2,
            padding: 0.005,
            cornerRadius: 1,
            subArcs: defaultArch,
          }}
          pointer={{
            color: "#345243",
            length: 0.8,
            width: 15,
            elastic: true,
          }}
          value={value ?? 0}
          minValue={0}
          maxValue={100}
          labels={{
            valueLabel: { formatTextValue: (value) => "" },
          }}
        />
        <Box sx={{ mt: "auto" }}>
          <AvgAndMax type={field} value={field} unit={"%"} />
        </Box>
      </CardContent>
    </Card>
  );
}
