import React, { useEffect } from "react";
import DeviceTypeBase from "./DeviceTypeBase";
import formatTemperature from "../../Functions/formatTemperature";
import { Grid } from "@mui/material";
import LastSeen from "../Cards/LastSeen";
import BatteryStatus from "../Cards/BatteryStatus";
import LastAlert from "../Cards/LastAlert";
import MoldIndex from "../Cards/MoldIndex";
import MoldIndexGraph from "../Graphs/MoldIndexGraph";
import TemperatureGuague from "../Guages/Temperature";
import GraphBase from "../Graphs/GraphBase";
import HumidityGuague from "../Guages/Humidity";
import FrameList from "../FrameList";
import DeviceActionLogs from "../Cards/Alerts";
import C02Guague from "../Guages/c02";
import { useSelector } from "react-redux";

export default function AirQuality(props) {
  const { isShare } = props;

  const [showMoldIndex, setShowMoldIndex] = React.useState(true);
  const deviceProfile = useSelector((state) => state.deviceProfile);

  useEffect(() => {
    if (deviceProfile) {
      if (typeof deviceProfile === "string") {
        let deviceProfileDecoded = JSON.parse(deviceProfile);

        if (!deviceProfileDecoded.moldIndex) return;

        if (deviceProfileDecoded.moldIndex.showMoldIndex) {
          setShowMoldIndex(true);
        } else {
          setShowMoldIndex(false);
        }
      }
    }
  }, [deviceProfile]);

  return (
    <Grid
      container
      spacing={2}
      alignItems={"stretch"}
      alignContent={"stretch"}
      sx={{ mt: 1 }}
    >
      <Grid item xs={12} md={isShare ? 6 : 4} sx={{ mb: 2 }}>
        <LastSeen />
      </Grid>
      <Grid item xs={12} md={isShare ? 6 : 4} sx={{ mb: 2 }}>
        <BatteryStatus />
      </Grid>
      {!isShare && (
        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <LastAlert />
        </Grid>
      )}
      {showMoldIndex && (
        <>
          <Grid item xs={12} md={3} sx={{ mb: 2 }}>
            <MoldIndex />
          </Grid>

          <Grid item xs={12} md={9} sx={{ mb: 2 }}>
            <MoldIndexGraph />
          </Grid>
        </>
      )}
      <Grid item xs={12} md={4} sx={{ mb: 2 }}>
        <TemperatureGuague field="temperature" />
      </Grid>
      <Grid item xs={12} md={4} sx={{ mb: 2 }}>
        <HumidityGuague field="humidity" />
      </Grid>
      <Grid item xs={12} md={4} sx={{ mb: 2 }}>
        <C02Guague field="co2" />
      </Grid>

      <Grid item xs={12} md={4} sx={{ mb: 2 }}>
        <GraphBase
          title="Temperature"
          value="temperature"
          formatter={formatTemperature}
          graphFormatter={formatTemperature}
          aspect={3.5}
          unit={formatTemperature(0, false, true)}
        />
      </Grid>

      <Grid item xs={12} md={4} sx={{ mb: 2 }}>
        <GraphBase title="Humidity" value="humidity" unit="%" aspect={3.5} />
      </Grid>

      <Grid item xs={12} md={4} sx={{ mb: 2 }}>
        <GraphBase title="C02" value="co2" unit="ppm" aspect={3.5} />
      </Grid>

      <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 2 }}>
        <FrameList />
      </Grid>

      {!isShare && (
        <>
          <Grid item xs={12} md={4}>
            <DeviceActionLogs />
          </Grid>
        </>
      )}
    </Grid>
  );
}
