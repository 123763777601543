import React, { useState } from "react";
import {
  CalendarMonth,
  Download,
  MenuRounded,
  Refresh,
  Settings,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useTheme } from "@emotion/react";

import downloadCSV from "../Functions/downloadCSV";
import { useSelector } from "react-redux";
import isOwner from "../Functions/isOwner";
import formatUnixDate from "../Functions/formatUnixDate";
import ActionAddDialog from "./Dialogs/actionAdd";
import DeletedeviceDialog from "./Dialogs/DeviceDelete";
import DeviceTransferDialog from "./Dialogs/deviceTransfer";
import ManageSharing from "./Dialogs/ManageSharing";
import DeviceNameChangeDialog from "./Dialogs/DeviceNameChange";
import DeviceChangeForwarderDialog from "./Dialogs/DeviceChangeForwarder";
import DateTimePickerDialog from "./Dialogs/DateTimePicker";
import DeviceSettings from "./Dialogs/DeviceSettings";
import { fetchDeviceHistory } from "../Functions/fetchDeviceHistory";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";
import PageTitle from "./PageTitle";
import { fetchDevices } from "../Functions/fetchDevices";
import DownlinkManager from "./Dialogs/downlinks";
import hasPermission from "../Functions/hasPermission";
import { fetchDevice } from "../Functions/fetchDevice";
import { fetchDeviceAlerts } from "../Functions/fetchDeviceAlerts";

export default function DeviceHeader(props) {
  const { t } = useTranslation("common");

  const theme = useTheme();

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const dateRange = useSelector((state) => state.dateRange);

  const isMobile = useSelector((state) => state.isMobile);

  const userOrganisationRoles = useSelector(
    (state) => state.userOrganisationRoles
  );

  const user = useSelector((state) => state.user);

  const device = useSelector((state) => state.device);

  const [anchorEl, setAnchorEl] = useState(null);
  const [sharingDialogOpen, setSharingDialogOpen] = useState(false);
  const [forwarderDialogOpen, setForwarderDialogOpen] = useState(false);
  const [openCommandManager, setOpenCommandManager] = useState(false);
  const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false);
  const [deviceOptionsOpen, setDeviceOptionsOpen] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const [openDeleteDevice, setOpenDeleteDevice] = useState(false);
  const [openTransferDevice, setOpenTransferDevice] = useState(false);

  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [openDeviceSettings, setOpenDeviceSettings] = useState(false);

  const handledeviceOptionsClose = () => {
    setDeviceOptionsOpen(false);
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <PageTitle
            theme={theme}
            title={device && device.name ? device.name : null}
            subTitle={device.dev_eui ? device.dev_eui : null}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          {!isMobile && (
            <Stack direction="row" spacing={1}>
              <Tooltip title={"Change Date/Time Period"} placement="top" arrow>
                <Button
                  variant="outlined"
                  color={
                    theme.palette.mode === "dark" ? "primary" : "secondary"
                  }
                  onClick={() => {
                    setOpenDateTimePicker(true);
                  }}
                  startIcon={!isMobile ? <CalendarMonth /> : null}
                >
                  {!isMobile ? (
                    dateRange.startDate && dateRange.startDate ? (
                      formatUnixDate(dateRange.startDate) +
                      " - " +
                      formatUnixDate(dateRange.endDate)
                    ) : (
                      ""
                    )
                  ) : (
                    <CalendarMonth />
                  )}
                </Button>
              </Tooltip>

              <Tooltip title={"Download CSV"} placement="top" arrow>
                <Button
                  variant="outlined"
                  color={
                    theme.palette.mode === "dark" ? "primary" : "secondary"
                  }
                  onClick={() => {
                    // name of file is device name + startDateTime + endDateTime.csv
                    downloadCSV(
                      deviceHistory,
                      device.name +
                        "-" +
                        //convert from unix timestamp to date string in local format
                        moment
                          .unix(dateRange.startDate)
                          .local()
                          .format(
                            localStorage.getItem("time_format") +
                              "-" +
                              localStorage.getItem("date_format")
                          ) +
                        "-" +
                        moment
                          .unix(dateRange.endDate)
                          .local()
                          .format(
                            localStorage.getItem("time_format") +
                              "-" +
                              localStorage.getItem("date_format")
                          ) +
                        ".csv"
                    );
                  }}
                >
                  <Download />
                </Button>
              </Tooltip>

              {
                // if user is not the owner of this device, don't show the add device button
                (selectedOrganisation &&
                  user &&
                  selectedOrganisation.owner_id === user.id) ||
                hasPermission(userOrganisationRoles, user.id, "edit") ? (
                  <Tooltip title={"Device Settings"} placement="top" arrow>
                    <Button
                      variant="outlined"
                      color={
                        theme.palette.mode === "dark" ? "primary" : "secondary"
                      }
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setDeviceOptionsOpen(true);
                      }}
                    >
                      <Settings />
                    </Button>
                  </Tooltip>
                ) : null
              }
              <Tooltip
                title={"Refresh Device"}
                placement="top"
                arrow
                enterDelay={500}
              >
                <Button
                  variant="outlined"
                  color={
                    theme.palette.mode === "dark" ? "primary" : "secondary"
                  }
                  onClick={() => {
                    fetchDevice(props.id);
                    fetchDeviceHistory(
                      props.id,
                      dateRange.startDate,
                      dateRange.endDate
                    );
                    fetchDeviceAlerts(
                      props.id,
                      dateRange.startDate,
                      dateRange.endDate
                    );
                    updateSnackbarMessage(
                      t("common.refreshed") + " " + moment().format("LLL")
                    );
                  }}
                >
                  <Refresh />
                </Button>
              </Tooltip>
            </Stack>
          )}
          {isMobile && (
            <>
              <Refresh
                onClick={() => {
                  fetchDeviceHistory(
                    props.id,
                    dateRange.startDate,
                    dateRange.endDate
                  );
                  updateSnackbarMessage(
                    t("common.refreshed") + " " + moment().format("LLL")
                  );
                }}
                sx={{
                  mr: 2,
                  color:
                    theme.palette.mode === "dark"
                      ? "#8ba1b7"
                      : theme.palette.primary.main,
                  //onhover set color to primary
                  "&:hover": {
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  },
                  fontSize: "2rem",
                  //onclick animate rotation
                  transition: "transform 0.5s",
                  "&:active": {
                    transform: "rotate(360deg)",
                  },
                }}
              />
              <MenuRounded
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setDeviceOptionsOpen(true);
                }}
                sx={{
                  mr: 2,
                  color:
                    theme.palette.mode === "dark"
                      ? "#8ba1b7"
                      : theme.palette.primary.main,
                  fontSize: "2rem",
                  "&:hover": {
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  },
                  //onclick animate rotation
                  transition: "transform 0.5s",
                  "&:active": {
                    transform: "rotate(360deg)",
                  },
                }}
              />
            </>
          )}
        </Grid>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={deviceOptionsOpen}
          onClose={handledeviceOptionsClose}
        >
          {isMobile && (
            <>
              <MenuItem
                key="change-date-time"
                onClick={() => {
                  setOpenDateTimePicker(true);
                  setDeviceOptionsOpen(false);
                  setAnchorEl(null);
                }}
              >
                <Typography variant="body2">Change Date/Time</Typography>
              </MenuItem>
              <MenuItem
                key="menu-device-settings"
                onClick={() => {
                  downloadCSV(
                    deviceHistory,
                    device.name +
                      "-" +
                      //convert from unix timestamp to date string in local format
                      moment
                        .unix(dateRange.startDate)
                        .local()
                        .format(
                          localStorage.getItem("time_format") +
                            "-" +
                            localStorage.getItem("date_format")
                        ) +
                      "-" +
                      moment
                        .unix(dateRange.endDate)
                        .local()
                        .format(
                          localStorage.getItem("time_format") +
                            "-" +
                            localStorage.getItem("date_format")
                        ) +
                      ".csv"
                  );
                  setDeviceOptionsOpen(false);
                  setAnchorEl(null);
                }}
              >
                <Typography variant="body2">Download CSV</Typography>
              </MenuItem>
            </>
          )}
          <MenuItem
            key="menu-device-settings"
            onClick={() => {
              setOpenDeviceSettings(true);
              setDeviceOptionsOpen(false);
            }}
          >
            <Typography variant="body2">Device Settings</Typography>
          </MenuItem>
          <MenuItem
            key="menu-command"
            onClick={() => {
              setOpenCommandManager(true);
              setDeviceOptionsOpen(false);
            }}
          >
            <Typography variant="body2">Send Command</Typography>
          </MenuItem>
          <MenuItem
            key="menu-change-name"
            onClick={() => setChangeNameDialogOpen(true)}
          >
            <Typography variant="body2">{t("common.change_name")}</Typography>
          </MenuItem>

          <MenuItem
            key="menu-manage-sharing"
            onClick={() => {
              setSharingDialogOpen(true);
              setDeviceOptionsOpen(false);
            }}
          >
            <Typography variant="body2">
              {t("common.manage_sharing")}
            </Typography>
          </MenuItem>
          {
            // transfer device
            //check if user is admin of org
            //if user is admin of org show transfer device
            isOwner(user, selectedOrganisation) ||
            hasPermission(userOrganisationRoles, user.id, "transfer") ? (
              <MenuItem
                key="menu-transfer-device"
                onClick={() => {
                  setOpenTransferDevice(true);
                  setDeviceOptionsOpen(false);
                }}
              >
                <Typography variant="body2">
                  {t("common.transfer_device")}
                </Typography>
              </MenuItem>
            ) : null
          }
          {isOwner(user, selectedOrganisation) ||
          hasPermission(userOrganisationRoles, user.id, "delete") ? (
            <MenuItem
              key="menu-delete-device"
              onClick={() => {
                setOpenDeleteDevice(true);
                setDeviceOptionsOpen(false);
              }}
            >
              <Typography
                variant="body2"
                //red text
                color="error"
              >
                {t("common.delete_device")}
              </Typography>
            </MenuItem>
          ) : null}
        </Menu>
      </Grid>

      <ActionAddDialog
        open={openActions}
        handleClose={() => setOpenActions(false)}
      />

      <DeletedeviceDialog
        open={openDeleteDevice}
        handleClose={() => setOpenDeleteDevice(false)}
        handleDelete={() => {
          fetchDevices(localStorage.getItem("selectedOrganisationID"));
          navigate("/");
          setOpenDeleteDevice(false);
        }}
      />

      <DeviceTransferDialog
        open={openTransferDevice}
        handleClose={() => setOpenTransferDevice(false)}
      />

      <ManageSharing
        open={sharingDialogOpen}
        handleClose={() => setSharingDialogOpen(false)}
      />

      <DeviceNameChangeDialog
        changeNameDialogOpen={changeNameDialogOpen}
        setChangeNameDialogOpen={setChangeNameDialogOpen}
        handledeviceOptionsClose={handledeviceOptionsClose}
      />

      <DeviceChangeForwarderDialog
        forwarderDialogOpen={forwarderDialogOpen}
        setForwarderDialogOpen={setForwarderDialogOpen}
        handledeviceOptionsClose={handledeviceOptionsClose}
      />

      <DateTimePickerDialog
        open={openDateTimePicker}
        onClose={() => setOpenDateTimePicker(false)}
      />
      <DeviceSettings
        open={openDeviceSettings}
        onClose={() => setOpenDeviceSettings(false)}
      />
      <DownlinkManager
        open={openCommandManager}
        handleClose={() => setOpenCommandManager(false)}
      />
    </Box>
  );
}
