import React from "react";
import { Grid } from "@mui/material";
import DeviceActionLogs from "../Cards/Alerts";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import FrameList from "../FrameList";
import GraphBase from "../Graphs/GraphBase";
import SignalQuality from "../Cards/SignalQuality";
import DeviceMetric from "../Cards/DeviceMetric";

export default function WaterLeakNBIOT(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 2 }}>
          <BatteryStatus field={"bat"} />
        </Grid>

        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 2 }}>
          <SignalQuality />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={3} sx={{ mb: 2 }}>
              <LastAlert />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <DeviceMetric
            title="Leak Detected?"
            value="leak"
            trueFalse={true}
            trueV="Leak Detected"
            falseV="No Leak"
            icon="water"
          />
        </Grid>

        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            title="Water Leak"
            value="leak"
            truefalse={true}
            trueV="Leak Detected"
            falseV="No Leak"
          />
        </Grid>

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
