import GaugeBase from "./GaugeBase";
import { useTheme } from "@emotion/react";
import { BugReport } from "@mui/icons-material";

export default function PMGuague(props) {
  const theme = useTheme();

  let defaultArch = [
    {
      //good, green
      limit: 12,
      color: "#1f883d",
    },
    {
      //satisfactory, light green
      limit: 35.4,
      color: "#14e34f",
    },
    {
      //moderate, yellow
      limit: 55.4,
      color: "#FFFF00",
    },
    {
      //poor, orange
      limit: 150.4,
      color: "#FFC300",
    },
    {
      //very poor red
      limit: 500,
      //dark red
      color: "#C70039",
    },
  ];

  let { value } = props;

  //check if value is an array, if it is, return first value
  if (Array.isArray(value)) {
    value = value[0];
  }

  let title = props.value.replace(/_/g, ".");
  title = props.title ? props.title : title;

  if (value === "PM2_5") {
    defaultArch = [
      {
        //good, green
        limit: 12,
        color: "#1f883d",
      },
      {
        //satisfactory, light green
        limit: 35.4,
        color: "#14e34f",
      },
      {
        //moderate, yellow
        limit: 55.4,
        color: "#FFFF00",
      },
      {
        //poor, orange
        limit: 150.4,
        color: "#FFA500",
      },
      {
        //very poor red
        limit: 350.4,
        //dark red
        color: "#EE4B2B",
      },
      {
        //very poor red
        limit: 500,
        //dark red
        color: "#800020",
      },
    ];
  } else if (value === "PM10") {
    defaultArch = [
      {
        //good, green
        limit: 54,
        color: "#1f883d",
      },
      {
        //satisfactory, light green
        limit: 154,
        color: "#14e34f",
      },
      {
        //moderate, yellow
        limit: 254,
        color: "#FFFF00",
      },
      {
        //poor, orange
        limit: 354,
        color: "#FFA500",
      },
      {
        //very poor red
        limit: 504,
        //dark red
        color: "#EE4B2B",
      },
      {
        //very poor red
        limit: 604,
        //dark red
        color: "#800020",
      },
    ];
  }

  let unit = "µg/m³";

  return (
    <GaugeBase
      title={title ? title : value}
      field={value}
      icon={
        <BugReport
          sx={{
            color: theme.palette.text.secondary,
            opacity: 0.8,
          }}
        />
      }
      defaultArch={defaultArch}
      unit={unit}
      min={0}
      max={value === "PM2_5" ? 500 : value === "PM10" ? 604 : 1000}
    />
  );
}
