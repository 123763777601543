// External dependencies
import React, { useEffect, useMemo } from "react";
import { ThemeProvider } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";

// Internal dependencies and styles
import "./App.css";
import { CONFIG } from "./config";
import Layout from "./components/Layout";
import AppRoutes from "./routes";
import { createAppTheme } from "./theme/appTheme";
import { useUrlStates } from "./hooks/useUrlStates";
import { useThemeManagement } from "./hooks/useThemeManagement";
import { useCollapsedState } from "./hooks/useCollapsedState";
import { useDataManagement } from "./hooks/useDataManagement";
import { store } from "./store";

const MemoizedAppRoutes = React.memo(AppRoutes);

// Create a wrapper component to handle URL states
export default function App() {
  // Authentication and user context
  const { getAccessTokenSilently, user } = useAuth0();

  // Redux state selectors
  const themeMode = useSelector((state) => state.themeMode);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  // Custom hooks for various app functionalities
  const { isShare } = useUrlStates();
  const { handleThemeChange } = useThemeManagement();
  const { collapsed, handleCollapsed } = useCollapsedState();

  // Create theme based on current theme mode
  const theme = useMemo(() => createAppTheme(themeMode, CONFIG), [themeMode]);

  // Setup data management
  useDataManagement(
    user,
    selectedOrganisation,
    isShare,
    getAccessTokenSilently
  );

  //check for window events and set isMobile
  const checkMobile = () => {
    if (window.innerWidth < 900) {
      store.dispatch({ type: "isMobile", payload: true });
    } else {
      store.dispatch({ type: "isMobile", payload: false });
    }
  };

  useEffect(() => {
    // Check mobile state on initial mount
    checkMobile();

    // Add event listener for resize
    const handleResize = () => checkMobile();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <Layout
            handleThemeChange={handleThemeChange}
            handleCollapsed={handleCollapsed}
            collapsed={collapsed}
            isShare={isShare}
          >
            <MemoizedAppRoutes
              collapsed={collapsed}
              handleThemeChange={handleThemeChange}
              isShare={isShare}
            />
          </Layout>
        </ThemeProvider>
      </LocalizationProvider>
    </DndProvider>
  );
}
